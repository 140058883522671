







import { defineComponent, Ref, ref, onMounted } from '@vue/composition-api';
import { useUserActions } from '@/store';
import { BALANCE_HEADER } from '@/constants/portfolio';

export default defineComponent({
  name: 'BalanceView',

  setup(_props) {
    const { getTransactionHistory } = useUserActions(['getTransactionHistory']);
    const tableItems: Ref<any> = ref([]);
    const isLoading = ref(false);

    const process = async () => {
      isLoading.value = true;
      const res = await getTransactionHistory();
      if (res) {
        tableItems.value = res || [];
        isLoading.value = false;
      }
    };

    onMounted(() => {
      process();
    });

    return {
      header: ref(BALANCE_HEADER),
      tableItems,
      isLoading,
      process
    };
  }
});
